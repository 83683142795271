import React from 'react';
import Auth from '../utils/auth';

class CallbackPage extends React.Component {
	componentDidMount() {
		const auth = new Auth();
		auth.handleAuthentication();
	}

	render() {
		return (
			<div>Ładowanie...</div>
		);
	};
};

export default CallbackPage;
